/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import RatingResponse from "./Responses/RatingResponse";
import FreeFormResponse from "./Responses/FreeFormResponse";
import BooleanResponse from "./Responses/BooleanResponse";
import SurveyComplete from "./SurveyComplete";
import { get, post } from "../API";
import { useSearchParams } from "react-router-dom";
import TextFieldResponse from "./Responses/TextFieldResponse";

const Survey = ({ surveyId }) => {
  const [selectedId, setSelectedId] = useState(null);
  const [complete, setComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [reviewLink, setReviewLink] = useState(null);
  const [homeLink, setHomeLink] = useState(null);
  const [requiredId, setRequiredId] = useState(null);
  const [showNoSurvey, setShowNoSurvey] = useState(false);
  const [imageHeight, setImageHeight] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [title, setTitle] = useState();
  const [questions, setQuestions] = useState();
  const [answers, setAnswers] = useState({});

  const [searchParams] = useSearchParams();

  const selectQuestion = (questionId) => {
    setSelectedId(questionId);
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      document.getElementById(questionId).scrollIntoViewIfNeeded();
    } else {
      document
        .getElementById(questionId)
        .scrollIntoView({ behavior: "smooth" });
    }
  };

  const loadSurvey = async () => {
    setIsLoading(true);
    try {
      const response = await get(`/s/${surveyId}`, {
        sid: searchParams.get("sid"),
      });
      const questions = response.data?.data?.questions;
      setQuestions(questions);
      setTitle(response.data?.data?.title);
      setImageUrl(response.data?.data?.image_url);
      setImageHeight(response.data?.data?.image_height);
      setSelectedId(questions[0].id);
    } catch (error) {
      console.log("Error");
      setShowNoSurvey(true);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadSurvey();
  }, []);

  if (complete) {
    return <SurveyComplete reviewLink={reviewLink} homeLink={homeLink} />;
  }
  if (isLoading) {
    return (
      <div className="d-flex vh-100 align-items-center justify-content-center">
        <span className="spinner-border spinner-border text-primary me-1" />
      </div>
    );
  }
  if (showNoSurvey) {
    return <div className="text-center mt-5">No survey found</div>;
  }

  const submitSurvey = async () => {
    let isValid = true;
    for (var q of questions) {
      if (!q.required) continue;
      if (!answers[q.id]) {
        selectQuestion(q.id);
        isValid = false;
        setRequiredId(q.id);
      }
    }
    if (!isValid) return;

    setIsSaving(true);
    // Convert answers to a list of question/value pairs
    const answersData = [];
    for (var k in answers) {
      if (!answers[k]) continue;
      answersData.push({
        question_id: k,
        value: answers[k].toString(),
      });
    }

    console.log(searchParams);

    const context = {
      smid: searchParams.get("smid"),
      source: searchParams.get("source"),
      sid: searchParams.get("sid"),
    };

    try {
      const response = await post(`/s/${surveyId}`, {
        answers: answersData,
        context,
      });
      setReviewLink(response.data?.data?.review_link);
      setHomeLink(response.data?.data?.home_link);
      setComplete(true);
    } catch (e) {
      console.log(e);
      setShowNoSurvey(true);
    }
    setIsSaving(false);
  };

  return (
    <div className="d-flex justify-content-center align-items-center flex-column">
      <div className="mb-3">
        <img
          src={imageUrl}
          alt="Brand"
          className="mt-3 pb-12"
          style={{ height: `${imageHeight || 60}px` }}
        />
      </div>
      <div className="mb-5">
        <h5 className="mb-4" style={{ fontWeight: 600, color: "#343a40" }}>
          {title}
        </h5>
        {questions?.map((item, index) => {
          const p = {
            item: item,
            itemNumber: index + 1,
            key: item.id,
            isLastItem: index >= questions.length - 1,
            isDisabled: selectedId !== item.id,
            showRequiredError: item.id === requiredId,
            onClick: () => setSelectedId(item.id),
            onSelectNextItem: () => {
              const nextIndex = index + 1;
              if (nextIndex >= questions.length) return;
              selectQuestion(questions[nextIndex].id);
            },
            onValueChange: (itemId, value) => {
              setAnswers({
                ...answers,
                [itemId]: value,
              });
            },
          };

          if (item.question_type === "rating") {
            return (
              <RatingResponse
                key={item.id}
                {...p}
                showSkipButton={!item.required}
                topLeftTitle={item.top_left_title}
                topRightTitle={item.top_right_title}
              />
            );
          } else if (item.question_type === "freeform") {
            return <FreeFormResponse key={item.id} {...p} />;
          } else if (item.question_type === "boolean") {
            return <BooleanResponse key={item.id} {...p} />;
          } else if (item.question_type === "textfield") {
            return <TextFieldResponse key={item.id} {...p} />;
          }
          return null;
        })}
        <div className="d-flex gap-2 flex-column text-center justify-content-center">
          <span
            className="align-items-center m-auto mb-2"
            style={{ maxWidth: "50ch" }}
          >
            By submitting this survey, I acknowledge that Specialized will use
            my information in accordance with its{" "}
            <a
              target="_blank"
              href="https://www.specialized.com/us/en/privacy-policy"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            .
          </span>
          <button
            disabled={isSaving}
            className="btn btn-primary"
            onClick={submitSurvey}
          >
            {isSaving && (
              <span className="spinner-border spinner-border-sm text-white me-1" />
            )}
            Submit Survey
          </button>
        </div>
        <div
          className="text-center mt-5 text-secondary"
          style={{ fontSize: "13px", fontWeight: 400 }}
        >
          <span className="d-block mb-1">Powered by</span>
          <a href="https://ikeono.com">
            <img
              alt="Ikeono Logo"
              height={16}
              src="https://images.squarespace-cdn.com/content/v1/63a3448b4bb6213ae43afb6d/2da25983-dabd-440f-8b14-033cf7ec7aac/SS+-+Logo.png?format=1500w"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Survey;
